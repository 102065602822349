import styled from 'styled-components'
import React from "react"

const SocialSignDivider = styled.span`
`

const Line = styled.p`
  font-family:${props => props.theme.mainFontParagraph};
  margin: 20px auto;
  text-align: center;
  font-size: 1rem;
  position:relative;
  z-index: 15;
  overflow:hidden;
  color:rgb(39, 39, 39);
  max-width:95%;
  &:before{
    content: "";
    display:block;
    width: 30%;
    height:1px;
    background: #909090;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 5;
    }
  &:after{
    content: "";
    display:block;
    width: 30%;
    height:1px;
    background: #909090;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 5;
    }
`

const LineText = ({children}) => {
  return <Line><SocialSignDivider>{children}</SocialSignDivider></Line>
}

export default LineText
